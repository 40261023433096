export enum UniverseActionTypes {
	SET_UNIVERSE = "SET_UNIVERSE",
	SET_CATEGORY = "SET_CATEGORY",
	SET_VOLUME = "SET_VOLUME",
	SET_LOADING = "SET_ISLOADING",
	SET_MUTE = "SET_ISMUTE",
	SET_FAVORITE = "SET_FAVORITE",
	SET_AUTO_NEXT = "SET_AUTO_NEXT",
	SET_CATEGORIES = "SET_CATEGORIES",
	SET_UNIVERSES = "SET_UNIVERSES",
	SET_PICKED_CATEGORY = "SET_PICKED_CATEGORY",
	SET_PICKED_UNIVERSE = "SET_PICKED_UNIVERSE",
}