export enum PomodoroActionTypes {
	SET_POMODORO_STATE = 'SET_POMODORO_STATE',
	SET_POMODORO_TIMER_STATE = 'SET_POMODORO_TIMER_STATE',
	SET_POMODORO_MAX_SHORT_BREAKS = 'SET_POMODORO_MAX_SHORT_BREAKS',
	SET_POMODORO_SHORT_BREAKS = 'SET_POMODORO_SHORT_BREAKS',
	SET_POMODORO_STATE_DEFAULT_DURATION = 'SET_POMODORO_STATE_DEFAULT_DURATION',
	TIMER_TICK = 'TIMER_TICK',
	TIMER_RESET = 'TIMER_RESET',
	TIMER_RESET_ALL = 'TIMER_RESET_ALL',
	TIMER_START = 'TIMER_START',
	TIMER_PAUSE = 'TIMER_PAUSE',
	TIMER_STOP = 'TIMER_STOP',
	TIMER_COMPLETE = 'TIMER_COMPLETE',
	ADD_ADDERTIME = 'ADD_ADDERTIME',
	REMOVE_ADDERTIME = 'REMOVE_ADDERTIME',
	ADD_POMODORO_TIMER_ADDER = 'ADD_POMODORO',
	IS_SETTINGS_OPEN = 'IS_SETTINGS_OPEN'
}